/* global Ladda */
import React from "react"
import { debounce } from "lodash"
import classnames from "classnames"
import PropTypes from "prop-types"

class LaddaButton extends React.Component {
  static propTypes = {
    buttonType: PropTypes.string,
    buttonStyle: PropTypes.string,
    buttonColor: PropTypes.string,
    buttonSize: PropTypes.string,
    spinnerSize: PropTypes.number,
    spinnerColor: PropTypes.string,
    onClick: PropTypes.func,
  }

  static defaultProps = {
    buttonType: "button",
    buttonStyle: "slide-left",
    spinnerColor: "#5677b5",
    disabled: false,
  }

  button = React.createRef()

  componentDidMount() {
    Ladda.bind(this.button.current)
  }

  componentDidUpdate() {
    if (this.props.stopOnUpdate) {
      Ladda.stopAll()
      Ladda.bind(this.button.current)
    }
  }

  componentWillUnmount() {
    Ladda.stopAll()
  }

  render() {
    return (
      <button
        type={this.props.buttonType}
        className={this.getClassNames()}
        disabled={this.props.disabled}
        ref={this.button}
        data-style={this.props.buttonStyle}
        data-color={this.props.buttonColor}
        data-size={this.props.buttonSize}
        data-spinner-size={this.props.spinnerSize}
        data-spinner-color={this.props.spinnerColor}
        data-pendo={this.props.dataPendo || null}
        onClick={
          this.props.onClick
            ? debounce(this.props.onClick, 5000, {
                leading: true,
                trailing: false,
              })
            : null
        }
      >
        {this.props.children}
      </button>
    )
  }

  getClassNames() {
    return classnames(this.props.className, "ladda-button")
  }
}

export function stopLaddaButton() {
  Ladda.stopAll()
}

export default LaddaButton
